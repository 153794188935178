<template>
    <div class="main box">
        <h1>Update Calendar event</h1>
        <div class="form-container">       

              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-text">
                   <div class="row">
                        <div class="col-100">
                            <!-- TEXT EN -->
                            <label>English</label>
                            <div class="form-group" :class="{ error: v$.form.en.$errors.length }">
                                <textarea class="form-control" v-model="v$.form.en.$model"></textarea>
                                <!-- error message -->
                                <template v-if="v$.form.en.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.en.$errors[0].$validator == 'max'">{{v$.form.en.$errors[0].$params.max}} caracters maximum allowed.</div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-100">
                            <!-- TEXT FR -->
                            <label>French</label>
                            <div class="form-group" :class="{ error: v$.form.fr.$errors.length }">
                                <textarea class="form-control" v-model="v$.form.fr.$model"></textarea>
                                <!-- error message -->
                                <template v-if="v$.form.fr.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.en.$errors[0].$validator == 'max'">{{v$.form.fr.$errors[0].$params.max}} caracters maximum allowed.</div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-100">
                            <!-- TEXT DE -->
                            <label>Deutsch</label>
                            <div class="form-group" :class="{ error: v$.form.de.$errors.length }">
                                <textarea class="form-control" v-model="v$.form.de.$model"></textarea>
                                <!-- error message -->
                                <template v-if="v$.form.de.$errors.length > 0">                
                                    <div class="error-msg" v-if="v$.form.de.$errors[0].$validator == 'max'">{{v$.form.de.$errors[0].$params.max}} caracters maximum allowed.</div>
                                </template>
                            </div>
                        </div>
                    </div>
                  </div>

                  <!-- RIGHT ROW -->
                  <div class="col-dates">  
                        <div class="row">
                            <div class="col-100">    
                                <!-- Month -->
                                <label>Month*</label>
                                <div class="form-group" :class="{ error: v$.form.month.$errors.length }">
                                    <select class="form-control" v-model="v$.form.month.$model">
                                        <option v-for="(item, idx) in months" :key="idx" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <!-- error message -->
                                    <template v-if="v$.form.month.$errors.length > 0">                
                                        <div class="error-msg" v-if="v$.form.month.$errors[0].$validator == 'required'">This field is required.</div>
                                        <div class="error-msg" v-if="v$.form.month.$errors[0].$validator == 'betweenValue'">Invalid month ID.</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-50">
                                <!-- Day start -->
                                <label>From*</label>
                                <div class="form-group" :class="{ error: v$.form.dayStart.$errors.length }">
                                    <input type="number" class="form-control" min="1" max="31" placeholder="From" v-model="v$.form.dayStart.$model" />                  
                                    <!-- error message -->
                                    <template v-if="v$.form.dayStart.$errors.length > 0">                
                                        <div class="error-msg" v-if="v$.form.dayStart.$errors[0].$validator == 'required'">This field is required.</div>
                                        <div class="error-msg" v-if="v$.form.dayStart.$errors[0].$validator == 'betweenValue'">Invalid day.</div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-50">
                                <!-- Day end -->
                                <label>To</label>
                                <div class="form-group" :class="{ error: v$.form.dayEnd.$errors.length }">
                                    <input type="number" class="form-control" min="1" max="31" placeholder="To" v-model="v$.form.dayEnd.$model" />                  
                                    <!-- error message -->
                                    <template v-if="v$.form.dayEnd.$errors.length > 0">                
                                        <div class="error-msg" v-if="v$.form.dayEnd.$errors[0].$validator == 'betweenValue'">Invalid day.</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                  </div>
                </div>
                   <!-- 
                    ***************************************************************************************  
                     SITE CHOICE
                    ***************************************************************************************  
                     -->
                     <div class="row row-choice">
                        <div class="col-100">
                          <div class="label">WEBSITES</div>
                          <div class="row row-type">
                                <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(1)},  {error : !form.sites.length }]" @click="switchSite(1)"></div>
                                  <div class="type-label">Safe Group</div>
                              </div>
                                <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(2)},  {error : !form.sites.length }]" @click="switchSite(2)"></div>
                                  <div class="type-label">Safe Orthopaedics</div>
                              </div>
                              <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(3)},  {error : !form.sites.length }]" @click="switchSite(3)"></div>
                                  <div class="type-label">Safe Medical</div>
                              </div>
                              <!-- <div class="type-item">
                                  <div :class="['checkbox-input', {selected : form.sites.includes(4)},  {error : !form.sites.length }]" @click="switchSite(4)"></div>
                                  <div class="type-label">LinkedIn</div>
                              </div> -->
                          </div>
                          <div class="error-msg error-center" v-if="!form.sites.length">Select at least one website</div>
                        </div>
                      </div>

                    <!-- 
                    ***************************************************************************************  
                    SUBMIT BUTTON
                    ***************************************************************************************  
                     -->
                    <div class="row-btn">
                        <input type="submit" :disabled="v$.form.$invalid || !form.sites.length" class="btn" value="Update" />
                    </div>     
        
            </form>
            <div class="notices errors" v-if="errors">An error has occured : 
              <div v-for="(item, idx) in errors" :key="idx">
                - {{ item }}
              </div>
            </div>
        </div>
    </div>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, maxLength, between } from '@vuelidate/validators';
import axios from "axios";

export default {
    name : "UpdateCalendar",
    data() {
        return {
            loading: false,
            errors : null,
            form : {
                sites : [],              
                en : null,
                fr : null,
                de : null,
                month: 0,
                dayStart : null,
                dayEnd : null
              
            },
            months : [
                {
                    id : 0,
                    name : "Select a month"
                },
                {
                    id : 1,
                    name : "January"
                },
                {
                    id : 2,
                    name : "February"
                },
                {
                    id : 3,
                    name : "March"
                },
                {
                    id : 4,
                    name : "April"
                },
                {
                    id : 5,
                    name : "May"
                },
                {
                    id : 6,
                    name : "June"
                },
                {
                    id : 7,
                    name : "July"
                },
                {
                    id : 8,
                    name : "August"
                },
                {
                    id : 9,
                    name : "September"
                },
                {
                    id : 10,
                    name : "October"
                },
                {
                    id : 11,
                    name : "November"
                },
                {
                    id : 12,
                    name : "December"
                },

            ]
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            form : {      
                en : {
                    max : maxLength(255)
                },
                fr : {
                    max : maxLength(255)
                },
                de : {
                    max : maxLength(255)
                },
                month :  {
                    required, betweenValue : between(1,12)
                },
                dayStart : {
                    required, betweenValue : between(1,31)
                },
                dayEnd : {
                    betweenValue : between(1,31)
                }
                
            }
        }        
    },
    methods : {
        switchSite(site) {
            this.form.sites.includes(site)  ? this.form.sites = this.form.sites.filter(e => e != site) : this.form.sites.push(site);
        },
        async onSubmit() {

            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) return;

            this.loading = true;
            this.errors = null;

            let data = {
              "_id" : this.$route.params.event, 
              "websites" : this.form.sites,
              "en" : this.form.en,
              "fr" : this.form.fr,
              "de" : this.form.de,
              "month" : this.form.month,
              "start" : this.form.dayStart,
              "end" : this.form.dayEnd
            }

            let config = {
                validateStatus: () => true,
            };
            config.headers = {  'x-access-token' : localStorage._safeToken }
            let vm = this

            axios
                .put(`${this.$store.state.apiUrl}/event`, data, config)
                .then(function (res) {
                    if(res.status != 200) {
                      vm.errors = res.data.errors;                     
                    }else {
                        const notice = {
                                msg : "Calendar event update with success!",
                                error : false
                            }
                        vm.$store.commit('setNotice', notice);
                        vm.success = true;
                        vm.$router.push({name : "Calendar"});
                    }
         
                })     
        },
    },
    mounted() {
        let config = {
            validateStatus: () => true,  
        };
        config.headers = {  'x-access-token' : localStorage._safeToken }
        let vm = this;
            axios
                .get(`${this.$store.state.apiUrl}/event/${this.$route.params.event}`,  config)
                .then(function (res) {                
                    if(res.status != 200) {
                        const notice = {
                            msg : res.data.errors,
                            error : true
                        }
                        vm.$store.commit('setNotice', notice);
                    }else{
                        vm.form.sites = res.data.message.websites;
                        vm.form.en = res.data.message.en;
                        vm.form.fr = res.data.message.fr;
                        vm.form.de = res.data.message.de;
                        vm.form.month = res.data.message.month;
                        vm.form.dayStart = res.data.message.start;
                        vm.form.dayEnd = res.data.message.end;
                    }
                });
    }
}
</script>

<style lang="scss" scoped>
  h1 {
    color:#fff;
    text-align: center;
    font-size:25px;
    text-transform: uppercase;
    font-weight: 300;
    margin:40px auto;
  }

  .col-text {
    width:66%;
    margin-right:4%;
  }

  .col-dates {
    width:30%;
  }
</style>